@tailwind base;
@tailwind components;
@tailwind utilities;

.home-section-div .image-container {
  height: 35%;
}

.about-section-div {
  min-height: 45vh;
}

.skills-section-div {
  min-height: 30vh;
}

.projects-section-div {
  min-height: 100%;
}

.about-section-img {
  max-width: 100;
  height: auto;
  width: auto\9;
}

.alternate-bg-dark {
  background-color: #6b7280;
}

.alternate-bg-bumblebee {
  background-color: #f1f5f9;
}

.alternate-bg-dark div.about-content {
  background-color: #4b5563;
}

.alternate-bg-bumblebee div.about-content {
  background-color: #e2e8f0;
}

.swiper-slide {
  height: auto !important;
  width: 100% !important;
}

.project-image {
  width: 100%;
  height: 100%;
  background-size: cover;
}
